// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorPage-module__errorPage--gQxnu{display:flex;justify-content:center;padding-top:10%;text-align:center}.ErrorPage-module__errorPage--gQxnu .ErrorPage-module__errorBody--Y6O7W{max-width:500px}.ErrorPage-module__errorPage--gQxnu .ErrorPage-module__errorBody--Y6O7W .ErrorPage-module__swoosh--sutkg{color:var(--eds-color-brand-orange);width:100px;height:100px;margin-left:10px}`, "",{"version":3,"sources":["webpack://./src/ErrorPage.module.styl"],"names":[],"mappings":"AAAA,oCACI,YAAQ,CACR,sBAAgB,CAChB,eAAY,CACZ,iBAAW,CAEX,wEACI,eAAU,CAEV,yGACI,mCAAM,CACN,WAAM,CACN,YAAO,CACP,gBAAY","sourcesContent":[".errorPage\n    display flex\n    justify-content center\n    padding-top 10%\n    text-align center\n\n    .errorBody\n        max-width 500px    \n\n        .swoosh\n            color var(--eds-color-brand-orange)\n            width 100px\n            height 100px\n            margin-left 10px\n      "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPage": `ErrorPage-module__errorPage--gQxnu`,
	"errorBody": `ErrorPage-module__errorBody--Y6O7W`,
	"swoosh": `ErrorPage-module__swoosh--sutkg`
};
export default ___CSS_LOADER_EXPORT___;
