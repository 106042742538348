const DEFAULT_PAGE_NOT_FOUND = {
  errorCode: 404,
  errorMessage: 'Page Not Found',
  additionalInfo: "We couldn't find the page you were looking for.",
}
const DEFAULT_UNAUTHORIZED_PAGE = {
  errorCode: 401,
  errorMessage: 'Unauthorized',
  additionalInfo: 'You are not authorized to view this page.',
}

export const DEFAULT_FORBIDDEN_PAGE = {
  errorCode: 403,
  errorMessage: 'Forbidden',
  additionalInfo: 'You do not have access to view this page.',
}

const DEFAULT_LINK_MESSAGE = 'Click to return to home'
const DEFAULT_HREF = '/'
const DEFAULT_ERROR_MESSAGE = 'An Error Has Occurred'
const DEFAULT_ADDITIONAL_ERROR_MESSAGE =
  'There was an issue on our end.  Please refresh the page to try again.'

export {
  DEFAULT_PAGE_NOT_FOUND,
  DEFAULT_UNAUTHORIZED_PAGE,
  DEFAULT_LINK_MESSAGE,
  DEFAULT_HREF,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ADDITIONAL_ERROR_MESSAGE,
}
