import styles from './ErrorPage.module.styl'
import {
  DEFAULT_PAGE_NOT_FOUND,
  DEFAULT_UNAUTHORIZED_PAGE,
  DEFAULT_FORBIDDEN_PAGE,
  DEFAULT_LINK_MESSAGE,
  DEFAULT_HREF,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ADDITIONAL_ERROR_MESSAGE,
} from './consts'
import { ErrorPageProps } from './types'
import { NikeSwoosh } from '@nike/nike-design-system-icons'

export const ErrorPage = ({
  errorCode,
  errorMessage = DEFAULT_ERROR_MESSAGE,
  additionalInfo = DEFAULT_ADDITIONAL_ERROR_MESSAGE,
  href = DEFAULT_HREF,
  linkMessage = DEFAULT_LINK_MESSAGE,
  LinkComponent = 'a' as unknown as React.ComponentType<
    {
      href: string
      children: React.ReactNode
      className?: string
    } & React.HTMLProps<HTMLAnchorElement>
  >,
  slackConfig,
}: ErrorPageProps) => {
  return (
    <div className={styles.errorPage}>
      <div className={styles.errorBody}>
        <NikeSwoosh className={styles.swoosh} />
        {errorCode && <p className='eds-type--title-1'>{errorCode}</p>}
        <p className='eds-type--title-4'>{errorMessage}</p>
        <br />
        <p className='eds-type--body-2'>{additionalInfo}</p>
        {slackConfig && (
          <p className='eds-type--body-2'>
            If this issue persists, please reach out to{' '}
            <a className='eds-link' rel='noreferrer' target='blank' href={slackConfig.channelUrl}>
              {slackConfig.channelName}
            </a>{' '}
            for help.
          </p>
        )}
        <br />
        {/* @ts-expect-error: className is not recognized on LinkComponent */}
        <LinkComponent href={href} className='eds-link'>
          {linkMessage}
        </LinkComponent>
      </div>
    </div>
  )
}

export const NotFoundPage = ({
  LinkComponent,
  slackConfig,
}: {
  LinkComponent?: React.ComponentType<{ href: string; children: React.ReactNode }>
  slackConfig?: { channelName: string; channelUrl: string }
}) => (
  <ErrorPage
    errorCode={DEFAULT_PAGE_NOT_FOUND.errorCode}
    errorMessage={DEFAULT_PAGE_NOT_FOUND.errorMessage}
    additionalInfo={DEFAULT_PAGE_NOT_FOUND.additionalInfo}
    LinkComponent={LinkComponent}
    slackConfig={slackConfig}
  />
)

export const UnauthorizedPage = ({
  LinkComponent,
  slackConfig,
}: {
  LinkComponent?: React.ComponentType<{ href: string; children: React.ReactNode }>
  slackConfig?: { channelName: string; channelUrl: string }
}) => (
  <ErrorPage
    errorCode={DEFAULT_UNAUTHORIZED_PAGE.errorCode}
    errorMessage={DEFAULT_UNAUTHORIZED_PAGE.errorMessage}
    additionalInfo={DEFAULT_UNAUTHORIZED_PAGE.additionalInfo}
    LinkComponent={LinkComponent}
    slackConfig={slackConfig}
  />
)

export const ForbiddenPage = ({
  LinkComponent,
  slackConfig,
}: {
  LinkComponent?: React.ComponentType<{ href: string; children: React.ReactNode }>
  slackConfig?: { channelName: string; channelUrl: string }
}) => (
  <ErrorPage
    errorCode={DEFAULT_FORBIDDEN_PAGE.errorCode}
    errorMessage={DEFAULT_FORBIDDEN_PAGE.errorMessage}
    additionalInfo={DEFAULT_FORBIDDEN_PAGE.additionalInfo}
    LinkComponent={LinkComponent}
    slackConfig={slackConfig}
  />
)
